import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Subsector({ sector }) {
  const data = useStaticQuery(graphql`
    query subsectorQuery {
      subsectors: allStrapiSubsectors {
        nodes {
          id
          title

          sector {
            title
          }
          projects {
            id
            title
            thumbnailSubtitle
            support
            slug
            cover {
              image {
                alt: alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const subsectors = data.subsectors.nodes

  return (
    <div>
      {subsectors.map(subsector => (
        <>
          {subsector.sector.title === sector.title ? (
            <>
              {subsector.projects.length > 0 && (
                <div id={subsector.id} className="mb-8 md:mb-12">
                  <h2 className="playfair-title ">{subsector.title}</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-6 md:gap-y-12 max-w-3xl">
                    {subsector.projects.map(project => (
                      <Link
                        to={"/projects/" + project.slug}
                        id={project.id}
                        className="h-full flex flex-col sm:grid sm:grid-cols-2 md:flex md:flex-col justify-start group overflow-hidden"
                      >
                        <GatsbyImage
                          alt={project.cover.image.alt}
                          image={getImage(project.cover.image.localFile)}
                          className="w-full h-0 pb-1/1 transition-transform duration-200 group-hover:scale-105"
                        />
                        <div className="p-6 bg-gray-300 h-full flex flex-col justify-start text-left items-start z-10">
                          <p className="montserrat-medium-8-200">
                            {project.thumbnailSubtitle}
                          </p>
                          <h3 className="playfair-no-upper">{project.title}</h3>
                          <p className="font-medium text-xs leading-5 tracking-60 my-4">
                            {/*{project.support}*/}
                            {project.support.length > 160 ? (
                              <> {project.support.substr(0, 159)}&hellip; </>
                            ) : (
                              project.support
                            )}
                          </p>
                          <Link
                            to={"/projects/" + project.slug}
                            className="mt-auto"
                          >
                            <button className="font-light text-sm border-b pb-0.5 border-black tracking-90 uppercase text-left transition-all duration-100 hover:border-transparent">
                              Read more
                            </button>
                          </Link>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : null}
        </>
      ))}
    </div>
  )
}
