import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import Layout from "../components/Layout"
import Subsector from "../components/Subsector"

export default function SectorPage(props) {
  const sector = props.data.strapiSectors
  return (
    <Layout
      Seo={{ title: sector.title }}
      noHero
      heroMain={
        <div className="flex flex-col-reverse sm:flex-col items-center sm:justify-end w-full h-full text-white md:pb-12 px-4">
          <div className="pb-2 text-lg tracking-90 uppercase flex flex-row items-center">
            <div className="bg-white w-20 h-px" />
            <p className="text-xl mx-3 font-light text-center">
              {sector.title}
            </p>
            <div className="bg-white w-20 h-px" />
          </div>
          <h1 className="pb-4 font-serif text-5xl tracking-90 text-center">
            {sector.subtitle}
          </h1>
        </div>
      }
      heroImage={
        <GatsbyImage
          style={{
            gridArea: "1/1",
            filter: "brightness(90%)",
          }}
          className="w-full h-full max-w-5xl mx-auto"
          image={getImage(sector.cover.localFile)}
          alt={sector.cover.alternativeText}
          loading="eager"
        />
      }
      content={
        <>
          <section className="section-container pt-5 md:pt-15">
            <div className="container px-4 flex flex-col items-center text-center space-y-6 md:space-y-12">
              <Subsector sector={sector} />
            </div>
          </section>
        </>
      }
    ></Layout>
  )
}

export const query = graphql`
  query sectorPageQuery($slug: String!) {
    strapiSectors(slug: { eq: $slug }) {
      slug
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      subsectors {
        title
      }
    }
  }
`
